import { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { handleFormErrors } from "../util.js";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../config/firebase";

import { useAlerts } from "../alerts.js";
import { Loading } from "../loadingContext.js";

export const ForgottenPassword = () => {
  const { addAlert } = useAlerts();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});
  const [loading, setLoading] = useState(false);

  const sendResetPasswordLink = httpsCallable(
    functions,
    "send_reset_password_link"
  );

  const sendResetEmail = async (e) => {
    e.preventDefault();

    let isValid = true;
    if (!email) {
      dispatchErrorUpdate({
        type: "set",
        field: "email",
        message: "Email is a required field",
      });
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    if (loading) {
      return <Loading />;
    }

    let data = {
      email: email,
    };

    try {
      setLoading(true);
      await sendResetPasswordLink(data);
      setLoading(false);
      addAlert({
        message:
          "Your reset password email was sent to the address provided. Please check your inbox",
        type: "success",
      });
    } catch (err) {
      console.log(err);
      // display form errors for invalid email, etc.
      addAlert({
        message: err.message,
        type: "error",
      });
      setLoading(false);
    }
  };

  return (
    <div className="h-screen">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 card bg-neutral w-96 shadow-xl">
        <figure className="p-4">
          <img alt="logo" src="/media/logo.jpg"></img>
        </figure>
        <form onSubmit={sendResetEmail} className="card-body">
          <input
            className="input input-bordered w-full max-w-xs"
            placeholder="Email"
            type="text"
            onChange={(e) => {
              setEmail(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "email" });
            }}
          />
          {errors?.email && (
            <span className="select-none text-error">{errors.email}</span>
          )}

          <div className="card-actions">
            <button className="btn btn-primary" type="submit">
              Send Link
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

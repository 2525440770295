import { getAuth, signOut } from "firebase/auth";
import { Link, NavLink } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useUserToken } from "./util";

export const NavBar = () => {
  const queryClient = useQueryClient();
  const token = useUserToken();
  const logout = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await signOut(auth);
      queryClient.clear();
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div className="navbar bg-neutral h-full max-w-full">
      <div className="flex-1">
        <img className="h-full w-96 p-0" src="/media/logo.jpg" alt="logo"></img>
      </div>
      <div className="flex-none h-full">
        <ul className="menu menu-horizontal px-1">
          <li className="hidden lg:inline-block">
            <NavLink
              className={({ isActive }) =>
                isActive ? "pointer-events-none" : "hover:bg-primary"
              }
              to="/"
            >
              Charts
            </NavLink>
          </li>
          <li className="hidden lg:inline-block">
            <Link className="hover:bg-primary" to="/status">
              Status
            </Link>
          </li>
          {token?.claims.type !== "user" &&
            token?.claims.type !== "superUser" && (
              <li className="hidden lg:inline-block">
                <Link className="hover:bg-primary" to="/admin">
                  Admin
                </Link>
              </li>
            )}
          <li className="hidden lg:inline-block">
            <button type="button" className="hover:bg-primary" onClick={logout}>
              Log out
            </button>
          </li>
          <li className="lg:hidden">
            <details className="flex-row">
              <summary>Menu</summary>
              <ul className="p-2 bg-base-100 rounded-t-none z-[2] w-36 right-0">
                <li>
                  <Link className="hover:bg-primary" to="/">
                    Charts
                  </Link>
                </li>
                <li>
                  <Link className="hover:bg-primary" to="/status">
                    Status
                  </Link>
                </li>
                {token?.claims.type !== "user" &&
                  token?.claims.type !== "superUser" && (
                    <li>
                      <Link className="hover:bg-primary" to="/admin">
                        Admin
                      </Link>
                    </li>
                  )}
                <li>
                  <button
                    type="button"
                    className="hover:bg-primary"
                    onClick={logout}
                  >
                    Log out
                  </button>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>
    </div>
  );
};

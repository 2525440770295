export const Footer = () => {
  return (
    <footer className="grid grid-cols-2 footer p-8 bg-primary text-base-content">
      <div>
        <p className="text-white text-sm font-light">© 2024 HydroMetrics™</p>
      </div>
      <div className="justify-self-end">
        <img
          className="h-full"
          src="/media/footer_logo.png"
          alt="footer_logo"
        ></img>
      </div>
    </footer>
  );
};

import React from "react";

export const QADialog = (props) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      reading: props.reading,
      QAFlag: !props.qaFlag,
    };
    props.onSubmit(data);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <h3 className="font-bold text-lg p-4">Quality Assurance</h3>
      <p className="pb-4">
        This will {props.qaFlag ? "remove" : "add"} QA on this data point
      </p>
      <div>
        <button type="submit" className="btn mr-1">
          Yes
        </button>
        <button type="button" className="btn ml-1" onClick={props.unSetDialog}>
          Cancel
        </button>
      </div>
    </form>
  );
};

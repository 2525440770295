import React, { useReducer, useState } from "react";
import { useCompanies } from "../../../api/queries/companies";
import { useSites } from "../../../api/queries/sites";
import { useUsers } from "../../../api/queries/users";

import Select from "react-select";
import {
  toOptionArray,
  handleFormErrors,
  customSelectStyles,
} from "../../util";
import { Loading } from "../../loadingContext";

export const PermissionDialog = (props) => {
  const companies = useCompanies({ activeOnly: false });
  const users = useUsers();
  const sites = useSites();

  const [User, setUser] = useState({
    value: props.permission?.UserID,
    label: props.permission?.UserName,
  });
  const [Company, setCompany] = useState({
    value: props.permission?.CompanyID,
    label: props.permission?.CompanyName,
  });
  const [Site, setSite] = useState(props.permission?.SiteID);
  const id = props.id || "";

  let siteOptions;
  if (sites.data) {
    siteOptions = toOptionArray(
      sites.data?.filter((s) => {
        return Company.value === s.data().CompanyID;
      }),
      "Name"
    );
    siteOptions = [{ value: null, label: "All Sites for Company" }].concat(
      siteOptions
    );
  }

  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    if (!Company.value) {
      dispatchErrorUpdate({
        type: "set",
        field: "company",
        message: "Company is a required field",
      });
      isValid = false;
    }
    if (!User.value) {
      dispatchErrorUpdate({
        type: "set",
        field: "user",
        message: "User is a required field",
      });
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    let UserID = User.value;
    let UserName = User.label;
    let CompanyID = Company.value;
    let CompanyName = Company.label;

    const data = {
      UserID,
      UserName,
      CompanyID,
      CompanyName,
      id,
    };
    data["SiteID"] = null;
    data["SiteName"] = null;

    if (Site !== null) {
      data["SiteID"] = Site;
      data["SiteName"] = siteOptions.find((s) => s.value == Site).label;
    }

    props.onSubmit(data);
  };

  if (sites.isLoading) return <Loading />;

  return (
    <form method="POST" onSubmit={handleSubmit}>
      {(companies.isLoading || users.isLoading || sites.isLoading) && (
        <Loading />
      )}
      <h3 className="font-bold text-lg p-4">
        {props.permission ? "Edit" : "Create"} Permission
      </h3>
      <div className="grid grid-rows-4 grid-flow-col gap-4">
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">User</span>
          <Select
            className="input w-full max-w-xs mx-auto rounded-md p-0"
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            value={User}
            options={toOptionArray(
              users.data?.filter((u) => {
                return u.data().Type === "user";
              }),
              "Name"
            )}
            onChange={(value) => {
              setUser(value);
              dispatchErrorUpdate({ type: "clear", field: "user" });
            }}
          ></Select>
          {errors?.user && (
            <span className="select-none text-error">{errors.user}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Company</span>
          <Select
            className="input w-full max-w-xs mx-auto rounded-md p-0"
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            value={Company}
            options={toOptionArray(companies.data, "Name")}
            onChange={(e) => {
              setSite(null);
              setCompany(e);
              dispatchErrorUpdate({ type: "clear", field: "company" });
            }}
          ></Select>
          {errors?.company && (
            <span className="select-none text-error">{errors.company}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Site</span>
          <Select
            className="input w-full max-w-xs mx-auto rounded-md p-0"
            styles={customSelectStyles}
            menuPortalTarget={document.body}
            value={siteOptions.find((s) => s.value == Site)}
            options={siteOptions}
            onChange={(e) => {
              setSite(e.value);
              dispatchErrorUpdate({ type: "clear", field: "site" });
            }}
          ></Select>
          {errors?.site && (
            <span className="select-none text-error">{errors.site}</span>
          )}
        </label>
      </div>
      <div>
        <button type="submit" className="btn mr-1">
          Submit
        </button>
        <button type="button" className="btn ml-1" onClick={props.unSetDialog}>
          Cancel
        </button>
      </div>
    </form>
  );
};

import { getAuth } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";

export const toUTC = (date) => {
  let dateString = date;
  let [day, month, year] = dateString.split("/");
  const dateObj = Date.UTC(+year, +month - 1, +day);
  return dateObj;
};

export const timestampToDateString = (timestamp) => {
  if (timestamp) {
    let dateObj = new Date(parseInt(timestamp));
    let formattedDate = dateObj.toISOString().substring(0, 10);
    return formattedDate;
  }
};
export const firestoreTimestampToDateString = (fsTimestamp) => {
  if (fsTimestamp) {
    return fsTimestamp.toDate().toISOString().substring(0, 10);
  }
};

export const firestoreTimestampToEpochTimestamp = (fsTimestamp) => {
  if (fsTimestamp) {
    return new Date(fsTimestamp.toMillis()).toISOString()
  }
};

export const dateToFirestoreTimestamp = (date) =>
  new Timestamp(new Date(date).getTime() / 1000, 0);

export const toOptionArray = (array, labelName) => {
  if (array) {
    if (array.length) {
      return array.map((value) => ({
        value: value.id,
        label: value.data()[labelName],
      }));
    }
  }
  return [];
};

export const handleFormErrors = (currentErrors, action) => {
  let newErrors = Object.assign({}, currentErrors);
  if (action.type === "set") {
    newErrors[action.field] = action.message;
  } else {
    delete newErrors[action.field];
  }
  return newErrors;
};

export const useUserToken = () => {
  const [token, setToken] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    if (auth.currentUser) {
      const user = auth.currentUser;
      user.getIdTokenResult().then((token) => {
        setToken(token);
      });
    }
  }, [auth]);

  return token;
};

export const customSelectStyles = {
  control: (base, state) => ({
    ...base,
    height: 50,
  }),
  menuPortal: (base, state) => ({
    ...base,
    zIndex: 9999,
  })
};
import {
  collection,
  doc,
  query,
  getDocs,
  updateDoc,
  addDoc,
  deleteDoc,
  where,
  setDoc,
} from "firebase/firestore";
import { useMutation, useQuery } from "@tanstack/react-query";

import { db } from "../../config/firebase";
import { queryClient } from "./client";
import { getAuth } from "firebase/auth";

export const useSites = () =>
  useQuery({
    queryKey: ["sites"],
    queryFn: async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      const token = await user.getIdTokenResult();
      try {
        let queryParams = [collection(db, "sites")];
        if (token.claims.type === "user") {
          queryParams.push(where("__name__", "in", token.claims.siteIDs));
        }
        const q = query(...queryParams);
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });

export const useSecurityKey = (siteID) =>
  useQuery({
    queryKey: ["securityKey", siteID],
    queryFn: async () => {
      try {
        const docID = await getDocs(
          collection(db, "sites", siteID, "securitykey")
        );
        return docID.docs[0]?.data().key || null;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    enabled: Boolean(siteID),
  });

export const useCreateSite = () =>
  useMutation({
    mutationKey: ["createSite"],
    mutationFn: async (siteData) => {
      const key = siteData.SecurityKey;
      delete siteData.SecurityKey;
      try {
        const site = await addDoc(collection(db, "sites"), siteData);
        if (key) {
          const subColRef = collection(db, "sites", site.id, "securitykey");
          await addDoc(subColRef, {
            key: Number(key),
          });
        }
      } catch (error) {
        console.log(error);
      }
      return doc;
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sites"]);
    },
  });

export const useEditSite = () =>
  useMutation({
    mutationKey: ["editSite"],
    mutationFn: async (siteData) => {
      const key = siteData.SecurityKey;
      delete siteData.SecurityKey;
      try {
        await updateDoc(doc(db, "sites", siteData.id), siteData);
        if (key) {
          const docID = await getDocs(
            collection(db, "sites", siteData.id, "securityKey")
          );
          const subColRef = doc(
            db,
            "sites",
            siteData.id,
            "securityKey",
            docID.docs[0].id
          );
          await setDoc(subColRef, {
            key: Number(key),
          });
        }
      } catch (error) {
        console.log(error);
      }
      return doc;
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sites"]);
    },
  });

export const useDeleteSite = (target_id) =>
  useMutation({
    mutationKey: ["deleteSite"],
    mutationFn: async (target_id) => {
      try {
        await deleteDoc(doc(db, "sites", target_id));
      } catch (error) {
        console.log(error);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sites"]);
    },
  });

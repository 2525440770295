// import React from 'react';
import { Navigate } from "react-router-dom";

import { useUserToken } from "../util";
import { Loading } from "../loadingContext";

const TYPE_CASCADE = {
  user: 0,
  superUser: 1,
  admin: 2,
};

export const PrivateRoute = ({ authenticated, ...rest }) => {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  const token = useUserToken();

  if (!authenticated) return <Navigate to="/login" />;
  if (!token) return <Loading />;

  if (
    rest.requiredUserType &&
    TYPE_CASCADE[token.claims.type] < TYPE_CASCADE[rest.requiredUserType]
  )
    return <Navigate to="/" />;
  return rest.children;
};

import React, { useReducer, useState } from "react";
import { handleFormErrors } from "../../util";

export const CompanyDialog = (props) => {
  const [Name, setName] = useState(props.company?.Name || "");
  const [Address, setAddress] = useState(props.company?.Address || "");
  const [Description, setDescription] = useState(
    props.company?.Description || ""
  );
  const [Active, setActive] = useState(
    props.company?.Active === undefined ? true : props.company.Active
  );
  const id = props.id || "";

  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;
    if (!Name) {
      dispatchErrorUpdate({
        type: "set",
        field: "name",
        message: "Name is a required field",
      });
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    const data = {
      Name,
      Description,
      Address,
      Active,
      id,
    };

    props.onSubmit(data);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <h3 className="font-bold text-lg p-4">
        {props.company ? "Edit" : "Create"} Company
      </h3>
      <div className="grid grid-rows-4 grid-flow-col gap-4">
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Company Name</span>
          <input
            type="text"
            placeholder="Company name"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={Name}
            onChange={(e) => {
              setName(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "name" });
            }}
          />
          {errors?.name && (
            <span className="select-none text-error">{errors.name}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Description</span>
          <input
            type="text"
            placeholder="Description"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={Description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Address</span>
          <input
            type="text"
            placeholder="Address"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={Address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </label>
        <label>
          <span className="block text-sm font-medium">Active</span>
          <input
            type="checkbox"
            checked={Active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </label>
      </div>
      <div>
        <button type="submit" className="btn mr-1">
          Submit
        </button>
        <button type="button" className="btn ml-1" onClick={props.unSetDialog}>
          Cancel
        </button>
      </div>
    </form>
  );
};

import { auth } from "../../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleFormErrors } from "../util";
import { Loading } from "../loadingContext";

export const Login = () => {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const navigate = useNavigate();
  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});
  const [loading, setLoading] = useState(false);

  const signIn = async (e) => {
    e.preventDefault();

    let isValid = true;
    if (!Email) {
      dispatchErrorUpdate({
        type: "set",
        field: "email",
        message: "Email is a required field",
      });
      isValid = false;
    }
    if (!Password) {
      dispatchErrorUpdate({
        type: "set",
        field: "password",
        message: "Password is a required field",
      });
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    if (loading) {
      return <Loading />;
    }

    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, Email, Password);
      setLoading(false);
      navigate(`/`);
    } catch (err) {
      dispatchErrorUpdate({
        type: "set",
        field: "password",
        message: "Invalid Credentials",
      });
      setLoading(false);
    }
  };

  return (
    <div className="h-screen">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 card bg-neutral w-96 shadow-xl">
        <figure className="p-4">
          <img alt="logo" src="/media/logo.jpg"></img>
        </figure>
        <form onSubmit={signIn} className="card-body">
          <input
            className="input input-bordered w-full max-w-xs"
            placeholder="Email"
            type="text"
            onChange={(e) => {
              setEmail(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "email" });
            }}
          />
          {errors?.email && (
            <span className="select-none text-error">{errors.email}</span>
          )}
          <input
            className="input input-bordered w-full max-w-xs"
            placeholder="Password"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "password" });
            }}
          />
          {errors?.password && (
            <span className="select-none text-error">{errors.password}</span>
          )}

          <a
            href="/forgotten-password"
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            Forgotten Password?
          </a>

          <div className="card-actions">
            <button className="btn btn-primary" type="submit">
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

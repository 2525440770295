import React from "react";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDialog } from "../../dialogContext";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

import { CompanyDialog } from "./dialog";
import {
  useCompanies,
  useCreateCompany,
  useEditCompany,
  useDeleteCompany,
} from "../../../api/queries/companies";
import { Loading } from "../../loadingContext";
import { useUserToken } from "../../util";

export const Companies = () => {
  useUserToken();

  const { unSetDialog, setDialog } = useDialog();
  const companies = useCompanies({activeOnly: false});
  const deleteCompany = useDeleteCompany();
  const createCompany = useCreateCompany();
  const editCompany = useEditCompany();

  const onNewCompany = (formData) => {
    createCompany.mutate(formData);
    unSetDialog();
  };

  const onEditCompany = (formData) => {
    editCompany.mutate(formData);
    unSetDialog();
  };

  const onDeleteCompany = (target_id) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record?",
      buttons: [
        { label: "Yes", onClick: () => deleteCompany.mutate(target_id) },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnClickOutside: true,
      closeOnEscape: true,
    });
  };

  return (
    <div className="overflow-x-auto w-full h-screen">
      {(createCompany.isPending ||
        editCompany.isPending ||
        deleteCompany.isPending) && <Loading />}
      <button
        className="m-2 fixed bottom-8 right-0 z-50"
        onClick={() =>
          setDialog(
            <CompanyDialog onSubmit={onNewCompany} unSetDialog={unSetDialog} />
          )
        }
      >
        <PlusCircleIcon className="h-10 w-10 z-50" />
      </button>
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Address</th>
            <th>Active</th>
            <th className="w-12 md:w-24"></th>
            <th className="w-24"></th>
          </tr>
        </thead>
        <tbody>
          {companies.isLoading ? (
            <Loading />
          ) : companies.isError ? (
            <></>
          ) : (
            companies.data?.map((companyDoc) => (
              <tr key={companyDoc.id}>
                <td>{companyDoc.data().Name}</td>
                <td>{companyDoc.data().Description}</td>
                <td>{companyDoc.data().Address}</td>
                {companyDoc.data().Active ? <td>Yes</td> : <td>No</td>}
                <td>
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() =>
                      setDialog(
                        <CompanyDialog
                          onSubmit={onEditCompany}
                          unSetDialog={unSetDialog}
                          company={companyDoc.data()}
                          id={companyDoc.id}
                        />
                      )
                    }
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() => onDeleteCompany(companyDoc.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

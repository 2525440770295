import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from "react-tooltip";

import { firestoreTimestampToEpochTimestamp, useUserToken } from "../../util";
import {
  useDeleteRecord,
  useEditRecord,
} from "../../../api/queries/sensorData";
import {
  XCircleIcon,
  FlagIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/solid";
import { useDialog } from "../../dialogContext";
import { QADialog } from "./qaDialog";
import { Loading } from "../../loadingContext";

export const Table = (props) => {
  const token = useUserToken();
  const { unSetDialog, setDialog } = useDialog();

  const deleteRecord = useDeleteRecord();
  const editRecord = useEditRecord();

  let [counter, setCounter] = useState(50);

  const onSubmitQAFlag = (formData) => {
    editRecord.mutate(formData);
    unSetDialog();
  };

  const onDeleteDataPoint = (targetID) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record?",
      buttons: [
        { label: "Yes", onClick: () => deleteRecord.mutate(targetID) },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnClickOutside: true,
      closeOnEscape: true,
    });
  };

  let flatArray = [];
  if (props.sensorData.data) {
    flatArray = Object.entries(props.sensorData.data).flatMap(
      ([deviceId, readings]) =>
        readings.map((r) => ({
          ...r,
          DeviceId: deviceId,
        }))
    );
    flatArray.sort((a, b) => a.Time - b.Time);
  }

  const toCSV = (arr) => {
    let array = [
      ["DeviceID", "Time", "PPM", "Temp", "Voltage", "Abs220", "Abs270"],
    ].concat(
      arr.map((item) => {
        return [
          item.DeviceId,
          firestoreTimestampToEpochTimestamp(item.Time),
          item.PPM,
          item.StartTemperatureExt,
          item.BatteryVoltage,
          item.Abs220,
          item.Abs270,
        ];
      })
    );
    let csv = array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8," });
    const objUrl = URL.createObjectURL(blob);
    window.location.assign(objUrl);
    URL.revokeObjectURL(objUrl);
  };

  return (
    <div className="overflow-x-auto">
      {(deleteRecord.isPending || editRecord.isPending) && <Loading />}
      <button
        className="m-2 z-50 btn btn-primary"
        onClick={(e) => {
          toCSV(flatArray);
        }}
      >
        <ArrowDownTrayIcon className="h-10 w-10" />
      </button>
      <table className="table table-zebra table-fixed">
        <thead>
          <tr>
            <th className="overflow-hidden hover:overflow-visible">DeviceID</th>
            <th className="overflow-hidden hover:overflow-visible">Time</th>
            <th className="overflow-hidden hover:overflow-visible">PPM</th>
            <th className="overflow-hidden hover:overflow-visible">Temp</th>
            <th className="overflow-hidden hover:overflow-visible">Voltage</th>
            <th className="overflow-hidden hover:overflow-visible">Abs220</th>
            <th className="overflow-hidden hover:overflow-visible">Abs270</th>
            <th className="text-wrap overflow-hidden hover:overflow-visible">
              Data Type
            </th>
            <th className="w-6 md:w-12"></th>
            <th className="w-12"></th>
          </tr>
        </thead>
        <tbody>
          {flatArray.slice(0, counter).map((d) => (
            <tr key={d.ID}>
              <td>{d.DeviceId}</td>
              <td>{firestoreTimestampToEpochTimestamp(d.Time)}</td>
              <td>{d.PPM}</td>
              <td>{d.StartTemperatureExt}</td>
              <td>{d.BatteryVoltage}</td>
              <td>{d.Abs220?.toFixed(1)}</td>
              <td>{d.Abs270?.toFixed(1)}</td>
              <td className="overflow-hidden hover:overflow-visible">
                {d.SensorDataTypeDescription}
              </td>
              <td>
                {d.SensorDataTypeDescription !== "Weather" && (
                  <button
                    data-tooltip-id="button-tooltip"
                    data-tooltip-content="QA a data point to exclude it from other reports"
                  >
                    <FlagIcon
                      className={"h-4 " + (d.QAFlag ? "fill-red-600" : "")}
                      onClick={() =>
                        setDialog(
                          <QADialog
                            unSetDialog={unSetDialog}
                            reading={d}
                            qaFlag={d.QAFlag}
                            onSubmit={onSubmitQAFlag}
                          />
                        )
                      }
                    />
                  </button>
                )}
              </td>
              <td>
                {token?.claims.type !== "user" &&
                  token?.claims.type !== "superUser" && (
                    <>
                      <button
                        data-tooltip-id="button-tooltip"
                        data-tooltip-content="Delete a data point to permanently remove it from records"
                      >
                        <XCircleIcon
                          className="h-4"
                          onClick={() => onDeleteDataPoint(d.ID)}
                        />
                      </button>
                    </>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {counter < flatArray.length && (
        <button
          className="btn btn-primary m-3"
          onClick={() => setCounter(counter + 50)}
        >
          Show more
        </button>
      )}
      <Tooltip id="button-tooltip" place="top" />
    </div>
  );
};

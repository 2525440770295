import { NavBar } from "../navbar";
import { Footer } from "../footer";
import {
  useCheckDeviceStatus,
  useSiteDownloadStatus,
  useDownloadSensorData,
} from "../../api/queries/sitedownloadstatus";
import { Loading } from "../loadingContext";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useAlerts } from "../alerts";
import { Tooltip } from "react-tooltip";

export const Status = () => {
  const { addAlert } = useAlerts();
  const siteDownloadStatus = useSiteDownloadStatus();
  const checkDeviceStatus = useCheckDeviceStatus();
  const downloadSensorData = useDownloadSensorData();

  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onDownloadSensorData = async (documentSnapshot) => {
    setIsDisabled(true);
    let devices = documentSnapshot?.map((d) => ({ ...d.data(), docID: d.id }));
    await downloadSensorData.mutateAsync(devices);
    setIsDisabled(false);
  };

  const onCheckDevice = async (device) => {
    let deviceID = device.data().DeviceID;
    try {
      setLoading(true);
      let response = await checkDeviceStatus.mutateAsync(device.data());
      setLoading(false);
      if (response["data"] === "error") {
        let alert = `There was an error retrieving data from device ${deviceID}`;
        addAlert({ message: alert, type: "error" });
      } else {
        let data = response["data"];
        let alert = (
          <>
            {`Device ${deviceID}`}
            <br />
            {`${data["Datetime"]}`}
            <br />
            {`Nitrate ${data["Nitrate Nitrogen(pnt)"]}`}
          </>
        );
        addAlert({ message: alert, type: "success" });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      {(siteDownloadStatus.isLoading || loading) && <Loading />}
      <div>
        <NavBar />
        <div className="min-h-screen">
          <h1 className="text-3xl p-2">
            Download Status
            <button
              disabled={isDisabled}
              className="m-2 btn btn-primary"
              onClick={(e) => {
                onDownloadSensorData(siteDownloadStatus.data);
              }}
            >
              <ArrowDownTrayIcon className="h-10 w-10" />
            </button>
          </h1>
          <div className="overflow-ellipsis">
            <table className="table table-fixed table-zebra">
              <thead>
                <tr>
                  <th className="w-20">Device</th>
                  <th className="w-60">Site</th>
                  <th className="w-20">Status</th>
                  <th>Last Download Date</th>
                  <th className="w-80">Message</th>
                  <th>Type</th>
                  <th>IP Address</th>
                  <th>Port</th>
                  <th>Frequency</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {siteDownloadStatus.data?.map((s) => (
                  <tr key={s.id}>
                    <td>{s.data().DeviceID}</td>
                    <td>{s.data().SiteName}</td>
                    <td>{s.data().SiteStatusDescription}</td>
                    <td>
                      {s
                        .data()
                        .LatestDate?.toDate()
                        .toISOString()
                        .substring(0, 10)}
                    </td>
                    <td>
                      <div className="line-clamp-3 overflow-clip">
                        {s.data().Message}
                      </div>
                    </td>
                    <td>{s.data().SensorDataTypeDescription}</td>
                    <td>{s.data().IPAddress}</td>
                    <td>{s.data().Port}</td>
                    <td>{s.data().DownloadFrequencyDescription}</td>
                    <td>
                      {s.data().SiteTypeDescription == "Trusense" && (
                        <button
                          className="btn btn-ghost btn-xs"
                          data-tooltip-id="button-tooltip"
                          data-tooltip-content="Check the latest status and nitrate reading of this sensor"
                          onClick={() => onCheckDevice(s)}
                        >
                          Check
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Footer />
      <Tooltip id="button-tooltip" place="top" />
    </>
  );
};

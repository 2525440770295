import React, { useEffect, useReducer } from "react";

const LoadingContext = React.createContext();

export const Spinner = () => {
  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-[#0006]">
        <div className="w-[300px] h-[300px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full h-full scale-[1.0] overflow-visible"
          >
            <g>
              <path
                className="fill-[#a0c8d7] animation-delay-250 animate-bounce"
                d="M94.805 68.012C94.805 79.504 88.177 85 80 85s-14.805-5.496-14.805-16.988S80 39.562 80 39.562s14.805 16.957 14.805 28.45z"
              />
              <path
                className="fill-[#a0c8d7] animation-delay-500 animate-bounce"
                d="M34.805 68.012C34.805 79.504 28.177 85 20 85S5.195 79.504 5.195 68.012 20 39.562 20 39.562s14.805 16.957 14.805 28.45z"
              />
              <path
                className="fill-[#77a4bd] animation-delay-750 animate-bounce"
                d="M72.808 58.829C72.808 76.533 62.596 85 50 85s-22.808-8.467-22.808-26.171S50 15 50 15s22.808 26.124 22.808 43.829z"
              />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
};

export const LoadingProvider = (props) => {
  const [loading, dispatchLoading] = useReducer((current, action) => {
    let newValue = current;
    if (action.type === "add") {
      newValue += 1;
    } else {
      newValue -= 1;
    }
    if (newValue < 0) newValue = 0;
    return newValue;
  }, 0);

  return (
    <LoadingContext.Provider
      value={{ dispatchLoading: dispatchLoading }}
      {...props}
    >
      {props.children}
      {loading > 0 && <Spinner />}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = React.useContext(LoadingContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a UserProvider");
  }
  return context;
};

export const Loading = () => {
  const { dispatchLoading } = useLoading();

  useEffect(() => {
    dispatchLoading({ type: "add" });
    return () => dispatchLoading({ type: "remove" });
  }, []);
  return <></>;
};

import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Loading } from "../../loadingContext";
import { useDialog } from "../../dialogContext";
import { SiteDialog } from "./dialog";
import {
  useSites,
  useCreateSite,
  useEditSite,
  useDeleteSite,
} from "../../../api/queries/sites";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useUserToken } from "../../util";

export const Sites = () => {
  useUserToken();

  const { unSetDialog, setDialog } = useDialog();
  const sites = useSites();
  const deleteSite = useDeleteSite();
  const createSite = useCreateSite();
  const editSite = useEditSite();

  const onNewSite = (formData) => {
    createSite.mutate(formData);
    unSetDialog();
  };

  const onEditSite = (formData) => {
    editSite.mutate(formData);
    unSetDialog();
  };

  const onDeleteSite = (target_id) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record?",
      buttons: [
        { label: "Yes", onClick: () => deleteSite.mutate(target_id) },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnClickOutside: true,
      closeOnEscape: true,
    });
  };

  return (
    <div className="overflow-x-auto w-full h-full">
      {(createSite.isPending || editSite.isPending || deleteSite.isPending) && (
        <Loading />
      )}
      <button
        className="m-2 fixed bottom-8 right-0 z-50"
        onClick={() =>
          setDialog(
            <SiteDialog onSubmit={onNewSite} unSetDialog={unSetDialog} />
          )
        }
      >
        <PlusCircleIcon className="h-10 w-10 z-50" />
      </button>
      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Company</th>
            <th>Site Name</th>
            <th>Serial Number</th>
            <th>Site type</th>
            <th>Data type</th>
            <th>Port</th>
            <th>IP Address</th>
            <th>Download frequency</th>
            <th>Active</th>
            <th className="w-12 md:w-24"></th>
            <th className="w-24"></th>
          </tr>
        </thead>
        <tbody>
          {sites.isLoading ? (
            <Loading />
          ) : sites.isError ? (
            <tr>
              <td colSpan="4">An error occured while loading data</td>
            </tr>
          ) : (
            sites.data.map((siteDoc) => (
              <tr key={siteDoc.id}>
                <td>{siteDoc.data().CompanyName}</td>
                <td>{siteDoc.data().Name}</td>
                <td>{siteDoc.data().SerialNumber}</td>
                <td>{siteDoc.data().SiteTypeDescription}</td>
                <td>{siteDoc.data().SensorDataTypeDescription}</td>
                <td>{siteDoc.data().Port}</td>
                <td>{siteDoc.data().IPAddress}</td>
                <td>{siteDoc.data().DownloadFrequencyDescription}</td>
                {siteDoc.data().Active ? <td>Yes</td> : <td>No</td>}
                <th>
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() =>
                      setDialog(
                        <SiteDialog
                          onSubmit={onEditSite}
                          unSetDialog={unSetDialog}
                          site={siteDoc.data()}
                          id={siteDoc.id}
                        />
                      )
                    }
                  >
                    Edit
                  </button>
                </th>
                <th>
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() => onDeleteSite(siteDoc.id)}
                  >
                    Delete
                  </button>
                </th>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

import React, { useReducer, useState } from "react";
import Select from "react-select";

import { handleFormErrors, customSelectStyles } from "../../util";
import { userTypes } from "./types";

export const EditUserDialog = (props) => {
  const [Name, setName] = useState(props.user?.Name || "");
  const [Email, setEmail] = useState(props.user?.Email || "");
  const [Type, setType] = useState(props.user?.Type || "");
  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});
  const ID = props.ID;

  let isValid = true;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!Name) {
      dispatchErrorUpdate({
        type: "set",
        field: "name",
        message: "Name is a required field",
      });
      isValid = false;
    }
    if (!Email) {
      dispatchErrorUpdate({
        type: "set",
        field: "email",
        message: "Email is a required field",
      });
      isValid = false;
    }
    if (!Type) {
      dispatchErrorUpdate({
        type: "set",
        field: "type",
        message: "Type is a required field",
      });
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    const data = {
      Name,
      Email,
      Type,
      ID,
    };

    props.onSubmit(data);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <h3 className="font-bold text-lg p-4">Edit User</h3>
      <div className="grid grid-rows-4 grid-flow-col gap-4">
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">User Name</span>
          <input
            type="text"
            placeholder="User name"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={Name}
            onChange={(e) => {
              setName(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "name" });
            }}
          />
          {errors?.name && (
            <span className="select-none text-error">{errors.name}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Email</span>
          <input
            type="text"
            placeholder="Email"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={Email}
            onChange={(e) => {
              setEmail(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "email" });
            }}
          />
          {errors?.email && (
            <span className="select-none text-error">{errors.email}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Type</span>
          <Select
            className="input w-full max-w-xs mx-auto rounded-md p-0"
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            value={userTypes.find((t) => t.value == Type)}
            options={userTypes}
            onChange={(e) => {
              setType(e.value);
              dispatchErrorUpdate({ type: "clear", field: "type" });
            }}
          ></Select>
          {errors?.type && (
            <span className="select-none text-error">{errors.type}</span>
          )}
        </label>
      </div>
      {props.error?.message && (
        <span className="select-none text-error">{props.error?.message}</span>
      )}
      <div>
        <button type="submit" className="btn mr-1">
          Submit
        </button>
        <button type="button" className="btn ml-1" onClick={props.unSetDialog}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export const CreateUserDialog = (props) => {
  const [Name, setName] = useState(props.user?.Name || "");
  const [Email, setEmail] = useState(props.user?.Email || "");
  const [Password, setPassword] = useState(props.user?.Password || "");

  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});
  let isValid = true;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!Name) {
      dispatchErrorUpdate({
        type: "set",
        field: "name",
        message: "Name is a required field",
      });
      isValid = false;
    }
    if (Password.length < 6) {
      dispatchErrorUpdate({
        type: "set",
        field: "password",
        message: "Password must be at least 6 characters long",
      });
      isValid = false;
    }
    if (!Email) {
      dispatchErrorUpdate({
        type: "set",
        field: "email",
        message: "Email is a required field",
      });
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    const data = {
      Name,
      Email,
      Password,
    };

    props.onSubmit(data);
  };

  return (
    <form className="flex flex-col gap-4" method="POST" onSubmit={handleSubmit}>
      <h3 className="font-bold text-lg">Create User</h3>
      <div className="grid grid-rows-3 grid-flow-col gap-4">
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">User Name</span>
          <input
            type="text"
            placeholder="User name"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={Name}
            onChange={(e) => {
              setName(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "name" });
            }}
          />
          {errors?.name && (
            <span className="select-none text-error">{errors.name}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Email</span>
          <input
            type="email"
            placeholder="Email"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={Email}
            onChange={(e) => {
              setEmail(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "email" });
            }}
          />
          {errors?.email && (
            <span className="select-none text-error">{errors.email}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Password</span>
          <input
            type="password"
            placeholder="Password"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={Password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {errors?.password && (
            <span className="select-none text-error">{errors.password}</span>
          )}
        </label>
      </div>
      {props.error?.message && (
        <span className="select-none text-error">{props.error?.message}</span>
      )}
      <div>
        <button type="submit" className="btn mr-1">
          Submit
        </button>
        <button type="button" className="btn ml-1" onClick={props.unSetDialog}>
          Cancel
        </button>
      </div>
    </form>
  );
};

import React, { useState } from "react";
import Select from "react-select";

import { timestampToDateString, toOptionArray } from "../../util";

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 35,
  }),
};

export const Options = (props) => {
  let [data, setData] = useState(props.searchParams);

  const displayOptions = [
    { value: "chart", label: "Chart" },
    { value: "table", label: "Table" },
  ];

  const groupByOptions = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
  ];

  return (
    <div className="drawer-side max-h-[90dvh]">
      <label
        htmlFor="chart-options-drawer"
        aria-label="close sidebar"
        className="drawer-overlay"
      ></label>
      <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
        <li>
          <label className="block">
            <span className="block text-sm font-medium">Display type</span>
            <Select
              value={displayOptions.find((e) => e.value === data.get("type"))}
              styles={customStyles}
              options={displayOptions}
              onChange={(e) => {
                setData((previous) => {
                  let newVal = new URLSearchParams(previous);
                  if (e.value === "chart") {
                    props.setShowChart(true);
                  } else if (e.value === "table") {
                    props.setShowChart(false);
                  }
                  newVal.set("type", e.value);
                  return newVal;
                });
              }}
            ></Select>
          </label>
        </li>
        <li>
          <div className="block">
            <span className="block text-sm font-medium">Company</span>
            <Select
              options={toOptionArray(props.companies, "Name")}
              styles={customStyles}
              isMulti
              onChange={(e) => {
                setData((previous) => {
                  let newVal = new URLSearchParams(previous);
                  newVal.delete("companies");
                  e.forEach((c) => newVal.append("companies", c.value));
                  let sensors = data.getAll("sensors");
                  let availableIDs = props.sensors
                    ?.filter((s) => {
                      return newVal
                        .getAll("companies")
                        .includes(s.data().CompanyID);
                    })
                    .map((s) => s.id);
                  sensors
                    .filter((v) => !availableIDs.includes(v))
                    .forEach((v) => newVal.delete("sensors", v));
                  return newVal;
                });
              }}
              value={toOptionArray(
                props.companies?.filter((c) =>
                  data.getAll("companies").includes(c.id)
                ),
                "Name"
              )}
            />
          </div>
        </li>
        <li>
          <div className="block w-full">
            <span className="block text-sm font-medium">Sensors</span>
            <Select
              isMulti
              styles={customStyles}
              options={
                data.getAll("companies").length
                  ? toOptionArray(
                      props.sensors?.filter((s) => {
                        return data
                          .getAll("companies")
                          .includes(s.data().CompanyID);
                      }),
                      "Name"
                    )
                  : toOptionArray(props.sensors, "Name")
              }
              onChange={(e) => {
                setData((previous) => {
                  let newVal = new URLSearchParams(previous);
                  newVal.delete("sensors");
                  e.map((s) => newVal.append("sensors", s.value));
                  return newVal;
                });
              }}
              value={toOptionArray(
                props.sensors?.filter((s) =>
                  data.getAll("sensors").includes(s.id)
                ),
                "Name"
              )}
            />
          </div>
        </li>
        {props.showChart && (
          <li>
            <label className="block">
              <span className="block text-sm font-medium">Variable 1</span>
              <Select
                isClearable
                styles={customStyles}
                value={toOptionArray(props.variables, "ColumnName").find(
                  (e) => e.value === data.get("var1")
                )}
                options={toOptionArray(props.variables, "ColumnName")}
                onChange={(e) => {
                  setData((previous) => {
                    let newVal = new URLSearchParams(previous);
                    newVal.delete("var1");
                    if (e && e.value) newVal.set("var1", e.value);
                    return newVal;
                  });
                }}
              ></Select>
            </label>
          </li>
        )}
        {props.showChart && (
          <li>
            <label className="block">
              <span className="block text-sm font-medium">Variable 2</span>
              <Select
                isClearable
                value={toOptionArray(props.variables, "ColumnName").find(
                  (e) => e.value === data.get("var2")
                )}
                styles={customStyles}
                options={toOptionArray(props.variables, "ColumnName")}
                onChange={(e) => {
                  setData((previous) => {
                    let newVal = new URLSearchParams(previous);
                    newVal.delete("var2");
                    if (e && e.value) newVal.set("var2", e.value);
                    return newVal;
                  });
                }}
              ></Select>
            </label>
          </li>
        )}
        {props.showChart && (
          <li>
            <label className="block">
              <span className="block text-sm font-medium">Group by</span>
              <Select
                isClearable
                value={groupByOptions.find(
                  (o) => o.value == data.get("groupBy")
                )}
                styles={customStyles}
                options={groupByOptions}
                onChange={(e) => {
                  setData((previous) => {
                    let newVal = new URLSearchParams(previous);
                    newVal.delete("groupBy");
                    if (e && e.value) newVal.set("groupBy", e.value);
                    return newVal;
                  });
                }}
              ></Select>
            </label>
          </li>
        )}

        <li>
          <label className="block">
            <span className="block text-sm font-medium">Start date:</span>
            <input
              type="date"
              className="input w-full max-w-xs"
              defaultValue={timestampToDateString(data.get("min"))}
              onChange={(e) => {
                setData((previous) => {
                  let newVal = new URLSearchParams(previous);
                  if (!e.target.value) {
                    newVal.delete("min");
                  } else {
                    newVal.set("min", new Date(e.target.value).getTime());
                  }
                  return newVal;
                });
              }}
            />
          </label>
        </li>
        <li>
          <label className="block">
            <span className="block text-sm font-medium">End date:</span>
            <input
              type="date"
              className="input w-full max-w-xs"
              defaultValue={timestampToDateString(data.get("max"))}
              onChange={(e) => {
                setData((previous) => {
                  let newVal = new URLSearchParams(previous);
                  if (!e.target.value) {
                    newVal.delete("max");
                  } else {
                    newVal.set("max", new Date(e.target.value).getTime());
                  }
                  return newVal;
                });
              }}
            />
          </label>
        </li>
        <li>
          <label className="block">
            <button
              type="button"
              className="btn btn-primary w-full"
              onClick={() => props.setSearchParams(data)}
            >
              Apply
            </button>
          </label>
        </li>
      </ul>
    </div>
  );
};

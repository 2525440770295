import {
  collection,
  doc,
  query,
  getDocs,
  updateDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { useMutation, useQuery } from "@tanstack/react-query";

import { db } from "../../config/firebase";
import { queryClient } from "./client";

export const usePermissions = () =>
  useQuery({
    queryKey: ["permissions"],
    queryFn: async () => {
      try {
        const q = query(collection(db, "permissions"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });

export const useCreatePermission = () =>
  useMutation({
    mutationKey: ["createPermission"],
    mutationFn: async (permissionData) => {
      try {
        await addDoc(collection(db, "permissions"), permissionData);
      } catch (error) {
        console.log(error);
      }
      return doc;
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["permissions"]);
    },
  });

export const useEditPermission = () =>
  useMutation({
    mutationKey: ["editPermission"],
    mutationFn: async (permissionData) => {
      try {
        await updateDoc(
          doc(db, "permissions", permissionData.id),
          permissionData
        );
      } catch (error) {
        console.log(error);
      }
      return doc;
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["permissions"]);
    },
  });

export const useDeletePermission = (target_id) =>
  useMutation({
    mutationKey: ["deletePermission"],
    mutationFn: async (target_id) => {
      try {
        await deleteDoc(doc(db, "permissions", target_id));
      } catch (error) {
        console.log(error);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["permissions"]);
    },
  });

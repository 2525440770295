import { collection, query, getDocs } from "firebase/firestore";
import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "./client";
import { getAuth } from "firebase/auth";
import { db, functions } from "../../config/firebase";
import { httpsCallable } from "firebase/functions";

getAuth();

export const useUsers = () =>
  useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      try {
        const q = query(collection(db, "users"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });

const createUser = httpsCallable(functions, "create_user");
const deleteUser = httpsCallable(functions, "delete_user");
const editUser = httpsCallable(functions, "edit_user");

export const useCreateUser = () =>
  useMutation({
    mutationKey: ["createUser"],
    mutationFn: async (userData) => {
      try {
        const uid = await createUser({ userData }).then((uid) => {
          return uid.data;
        });
        return uid;
      } catch (err) {
        return {
          error: err,
        };
      }
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["createUser"]);
    },
  });

export const useEditUser = () =>
  useMutation({
    mutationKey: ["editUser"],
    mutationFn: async (userData) => {
      try {
        await editUser({ userData }).then((e) => {});
      } catch (err) {
        return { error: err };
      }
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["editUser"]);
    },
  });

export const useDeleteUser = () =>
  useMutation({
    mutationKey: ["deleteUser"],
    mutationFn: async (uid) => {
      try {
        await deleteUser({ uid }).then((result) => {});
      } catch (err) {
        return { error: err };
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["deleteUser"]);
    },
  });

import React, { useState } from "react";

import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import enableExporting from "highcharts/modules/exporting";

import { Footer } from "../../footer";
import { NavBar } from "../../navbar";
import { Options } from "./options";
import { useCompanies } from "../../../api/queries/companies";
import { useVariables } from "../../../api/queries/variables";
import { useSensors } from "../../../api/queries/sensors";
import { useSearchParams } from "react-router-dom";
import { useChartData } from "../../../api/queries/sensorData";
import { Table } from "./table";
import { Loading } from "../../loadingContext";
import { Bars3Icon } from "@heroicons/react/24/solid";

enableExporting(Highcharts);

export const Charts = () => {
  let today = new Date();
  let oneWeekAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  let [searchParams, setSearchParams] = useSearchParams({
    min: oneWeekAgo.getTime(),
    max: today.getTime(),
  });
  let showChartState = searchParams.get("type") === "table" ? false : true;
  const [showChart, setShowChart] = useState(showChartState);
  const companies = useCompanies({ activeOnly: true });
  const sensors = useSensors({ activeOnly: true });
  const variables = useVariables();
  const sensorData = useChartData({
    sensors: searchParams.getAll("sensors") || [],
    startDate: searchParams.get("min"),
    endDate: searchParams.get("max"),
  });
  const selectedOptions = searchParams.size;

  let devices = [];
  if (sensorData.data) {
    devices = searchParams
      .getAll("sensors")
      .map((id) => sensors.data.find((sensor) => sensor.id === id).data());
  }
  let var1 =
    variables.data?.filter((v) => v.id === searchParams.get("var1"))[0] || null;
  let var2 =
    variables.data?.filter((v) => v.id === searchParams.get("var2"))[0] || null;
  let yAxis = 1;
  let chartSeries = devices?.flatMap((device) => {
    let returnVal = [];
    if (var1) {
      let data = sensorData.data[device.DeviceID]
        .filter((doc) => !doc.QAFlag)
        .map((doc) => {
          return [doc.Time.seconds * 1000, doc[var1.data().ColumnName]];
        });
      returnVal.push({
        data,
        name: device.Name + " - " + var1.data().ColumnName,
      });
    }
    if (var2) {
      let data = sensorData.data[device.DeviceID]
        .filter((doc) => !doc.QAFlag)
        .map((doc) => {
          return [doc.Time.seconds * 1000, doc[var2.data().ColumnName]];
        });
      if (var1.id !== var2.id) {
        yAxis = 1;
      }
      returnVal.push({
        data,
        name: device.Name + " - " + var2?.data().ColumnName,
        yAxis: yAxis,
      });
    }
    return returnVal;
  });

  let title = `${devices.map((device) => device.Name).join(", ")}`;

  let chartOptions = {
    title: {
      text: title,
    },

    series: chartSeries,
    accessibility: {
      enabled: false,
    },
    navigator: {
      enabled: true,
      series: [],
    },
    exporting: {
      buttons: {
        contextButton: {
          symbol: "menuball",
        },
      },
      chartOptions: {
        plotOptions: {
          series: {
            dataLabels: {
              enabled: false,
            },
          },
        },
      },
      fallbackToExportServer: false,
    },
    plotOptions: {
      series: {
        dataGrouping: {
          enabled: true,
          forced: searchParams.get("groupBy") !== undefined,
          units: searchParams.get("groupBy") ? [[searchParams.get("groupBy"), [1]]] : undefined,
        }
      }
    },
    rangeSelector: {
      buttons: [
        {
          type: "hour",
          count: 1,
          text: "1h",
        },
        {
          type: "hour",
          count: 4,
          text: "4h",
        },
        {
          type: "day",
          count: 1,
          text: "1D",
        },
        {
          type: "week",
          count: 1,
          text: "1W",
        },
        {
          type: "month",
          count: 1,
          text: "1M",
        },
        {
          type: "all",
          count: 1,
          text: "All",
        },
      ],
      inputEnabled: false,
      enabled: true,
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%e-%b-%Y}",
      },
      min: Number(searchParams.get("min")) || null,
      max: Number(searchParams.get("max")) || null,
    },
    yAxis: [
      {
        title: { text: var1?.data().ColumnName },
        labels: { format: "{value:.2f}" },
      },
      {
        title: { text: var2?.data().ColumnName },
        labels: { format: "{value:.2f}" },
        opposite: true,
      },
    ],
    pilotOptions: {
      series: {
        point: {
          events: {
            mouseOver(e) {},
          },
        },
      },
    },
  };

  return (
    <div>
      <NavBar />
      <div className="min-h-screen">
        <div className="drawer lg:drawer-open">
          <input
            id="chart-options-drawer"
            type="checkbox"
            className="drawer-toggle"
          />
          <div className="drawer-content">
            <div className="flex">
              <label
                htmlFor="chart-options-drawer"
                className="btn btn-ghost btn-sm drawer-button lg:hidden m-2 justify-self-start"
              >
                <Bars3Icon className="h-8 w-8" />
              </label>
            </div>
            {sensorData.isLoading ? (
              <Loading />
            ) : (
              <>
                {selectedOptions > 2 ? (
                  <>
                    {showChart ? (
                      <HighchartsReact
                        containerProps={{
                          className: "h-[50lvh] lg:h-auto lg:aspect-video",
                        }}
                        highcharts={Highcharts}
                        options={chartOptions}
                      />
                    ) : (
                      <Table sensorData={sensorData} />
                    )}
                  </>
                ) : (
                  <h1 className="pt-3">
                    Select details on the left to see data
                  </h1>
                )}
              </>
            )}
          </div>
          {companies.isLoading || sensors.isLoading || variables.isLoading ? (
            <Loading />
          ) : (
            <Options
              companies={companies.data}
              sensors={sensors.data}
              variables={variables.data}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
              setShowChart={setShowChart}
              showChart={showChart}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

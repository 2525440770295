import {
  collection,
  query,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  where,
  deleteDoc,
  or,
} from "firebase/firestore";
import { useMutation, useQuery } from "@tanstack/react-query";

import { db, functions } from "../../config/firebase";
import { httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { queryClient } from "./client";

const checkStatus = httpsCallable(functions, "check_device_status");
const downloadSensorData = httpsCallable(functions, "download_sensor_data");

export const useSiteDownloadStatus = () =>
  useQuery({
    queryKey: ["sitedownloadstatus"],
    queryFn: async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      const token = await user.getIdTokenResult();
      try {
        if (token.claims.type === "user") {
          let queryParams = [collection(db, "sitedownloadstatus")];
          if (token.claims.siteIDs.length && !token.claims.companyIDs.length) {
            queryParams.push(where("SiteID", "in", token.claims.siteIDs));
            const querySnapshot = await getDocs(query(...queryParams));
            return querySnapshot.docs;
          }
          if (token.claims.companyIDs.length && !token.claims.siteIDs.length) {
            queryParams.push(where("CompanyID", "in", token.claims.companyIDs));
            const querySnapshot = await getDocs(query(...queryParams));
            return querySnapshot.docs;
          }
          if (token.claims.companyIDs.length && token.claims.siteIDs.length) {
            queryParams.push(
              or(
                where("CompanyID", "in", token.claims.companyIDs),
                where("SiteID", "in", token.claims.siteIDs)
              )
            );
            const querySnapshot = await getDocs(query(...queryParams));
            return querySnapshot.docs;
          }
          const q = query(...queryParams);
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs;
        }
        const querySnapshot = await getDocs(
          collection(db, "sitedownloadstatus")
        );
        return querySnapshot.docs;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });

export const useCreateSensorStatus = () =>
  useMutation({
    mutationKey: ["createSiteStatus"],
    mutationFn: async (siteStatusData) => {
      try {
        const docRef = await addDoc(
          collection(db, "sitedownloadstatus"),
          siteStatusData
        );
        return docRef.id;
      } catch (err) {
        return {
          error: err,
        };
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["sitedownloadstatus"]);
    },
  });

export const useDeleteSiteDownloadStatus = () =>
  useMutation({
    mutationKey: ["deleteSiteStatus"],
    mutationFn: async (SensorID) => {
      try {
        const status = (
          await getDocs(
            query(
              collection(db, "sitedownloadstatus"),
              where("SensorID", "==", SensorID)
            )
          )
        ).docs[0];
        const docRef = await deleteDoc(
          doc(db, "sitedownloadstatus", status.id)
        );
        return docRef.id;
      } catch (err) {
        return {
          error: err,
        };
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["sitedownloadstatus"]);
    },
  });

export const useEditSensorStatus = () =>
  useMutation({
    mutationKey: ["editSiteStatus"],
    mutationFn: async (siteStatusData) => {
      try {
        siteStatusData.SensorID = siteStatusData.id;

        delete siteStatusData.id;
        delete siteStatusData.Name;
        delete siteStatusData.Sensor;
        const sensorStatus = (
          await getDocs(
            query(
              collection(db, "sitedownloadstatus"),
              where("SensorID", "==", siteStatusData.SensorID)
            )
          )
        ).docs[0];
        await updateDoc(
          doc(db, "sitedownloadstatus", sensorStatus.id),
          siteStatusData
        );
      } catch (err) {
        return {
          error: err,
        };
      }
      return doc;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["sitedownloadstatus"]);
    },
  });

export const useCheckDeviceStatus = () =>
  useMutation({
    mutationKey: ["checkDeviceStatus"],
    mutationFn: async (device) => {
      try {
        const status = await checkStatus(device);
        return status;
      } catch (err) {
        return {
          error: err,
        };
      }
    },
  });

export const useDownloadSensorData = () =>
  useMutation({
    mutationKey: ["downloadSensorData"],
    mutationFn: async (devices) => {
      try {
        await downloadSensorData({ devices }).then((s) => {
          return s;
        });
      } catch (err) {
        return {
          error: err,
        };
      }
    },
  });

import React, { useReducer, useState } from "react";
import { useCompanies } from "../../../api/queries/companies";
import { useSites } from "../../../api/queries/sites";
import Select from "react-select";

import {
  dateToFirestoreTimestamp,
  firestoreTimestampToDateString,
  handleFormErrors,
  toOptionArray,
  customSelectStyles
} from "../../util";
import { Loading } from "../../loadingContext";

export const SensorDialog = (props) => {
  const companies = useCompanies({ activeOnly: false });
  const sites = useSites();

  const [Site, setSite] = useState({
    value: props.sensor?.SiteID,
    label: props.sensor?.SiteName,
  });
  const [DeviceID, setDeviceID] = useState(props.sensor?.DeviceID || "");
  const [sDate, setStartDate] = useState(
    firestoreTimestampToDateString(props.sensor?.StartDate)
  );
  const [Active, setActive] = useState(
    props.sensor?.Active === undefined ? true : props.sensor.Active
  );
  const id = props.id || "";

  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    if (!Site.value) {
      dispatchErrorUpdate({
        type: "set",
        field: "site",
        message: "Site is a required field",
      });
      isValid = false;
    }
    if (!DeviceID) {
      dispatchErrorUpdate({
        type: "set",
        field: "deviceID",
        message: "Device ID is a required field",
      });
      isValid = false;
    }
    if (!sDate) {
      dispatchErrorUpdate({
        type: "set",
        field: "startDate",
        message: "Start Date is a required field",
      });
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    let SiteID = Site.value;
    let SiteName = Site.label;
    let StartDate = dateToFirestoreTimestamp(sDate);
    let Name = DeviceID + " " + SiteName;

    const data = {
      Name,
      SiteID,
      SiteName,
      DeviceID,
      StartDate,
      Active,
      id,
      SiteData: sites.data.find((doc) => doc.id === SiteID),
    };

    data.DeviceID = Number(DeviceID);

    props.onSubmit(data);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      {(companies.isLoading || sites.isLoading) && <Loading />}
      <h3 className="font-bold text-lg p-4">
        {props.sensor ? "Edit" : "Create"} Sensor
      </h3>
      <div className="grid grid-rows-4 grid-flow-col gap-4">
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Site</span>
          <Select
            className="input w-full max-w-xs mx-auto rounded-md p-0"
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            value={Site}
            options={toOptionArray(sites.data, "Name")}
            onChange={(value) => {
              setSite(value);
              dispatchErrorUpdate({ type: "clear", field: "site" });
            }}
          ></Select>
          {errors?.site && (
            <span className="select-none text-error">{errors.site}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Device ID</span>
          <input
            type="number"
            placeholder="Device ID"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={DeviceID}
            onChange={(e) => {
              setDeviceID(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "deviceID" });
            }}
          />
          {errors?.deviceID && (
            <span className="select-none text-error">{errors.deviceID}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Start Date</span>
          <input
            type="date"
            placeholder="Start Date"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={sDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "startDate" });
            }}
          />
          {errors?.startDate && (
            <span className="select-none text-error">{errors.startDate}</span>
          )}
        </label>
        <label>
          <span className="block text-sm font-medium">Active</span>
          <input
            type="checkbox"
            checked={Active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </label>
      </div>
      <div className="flex flex-row gap-2 justify-center">
        <button type="submit" className="btn">
          Submit
        </button>
        <button type="button" className="btn" onClick={props.unSetDialog}>
          Cancel
        </button>
      </div>
    </form>
  );
};

import {
  collection,
  doc,
  query,
  getDocs,
  updateDoc,
  deleteDoc,
  where,
  getDoc,
  arrayRemove,
} from "firebase/firestore";
import { useMutation, useQuery } from "@tanstack/react-query";

import { db } from "../../config/firebase";
import { queryClient } from "./client";
import { dateToFirestoreTimestamp } from "../../components/util";

export const useDeleteRecord = (targetID) =>
  useMutation({
    mutationKey: ["deleteRecord"],
    mutationFn: async (targetID) => {
      try {
        await deleteDoc(doc(db, "sensorreadings", targetID));
      } catch (error) {
        console.log(error);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sensorreadings"]);
    },
  });

export const useEditRecord = () =>
  useMutation({
    mutationKey: ["editRecord"],
    mutationFn: async (recordData) => {
      try {
        const docRef = doc(db, "sensorreadings", recordData.reading.DocID);
        const sensorReading = await getDoc(docRef);
        const readingsArray = sensorReading.data().readings;
        const index = sensorReading
          .data()
          .readings.findIndex(
            (reading) => reading.ReadingID === recordData.reading.ReadingID
          );
        readingsArray[index].QAFlag = recordData.QAFlag;
        await updateDoc(docRef, { readings: arrayRemove() });
        await updateDoc(docRef, { readings: readingsArray });
      } catch (error) {
        return error;
      }
      return doc;
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sensorreadings"]);
    },
  });

export const useSensorData = () =>
  useQuery({
    queryKey: ["sensorreadings"],
    queryFn: async () => {
      try {
        const q = query(collection(db, "sensorreadings"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });

export const useChartData = ({ sensors, startDate, endDate }) =>
  useQuery({
    queryKey: ["sensorreadings", sensors, { startDate }, { endDate }],
    queryFn: async () => {
      try {
        let data = {};
        let sensorIds = [];
        let start = startDate ? new Date(parseInt(startDate)) : undefined;
        let end = endDate ? new Date(parseInt(endDate)) : undefined;
        const sensorQuery = query(
          collection(db, "sensors"),
          where("__name__", "in", sensors)
        );
        const sensorDocs = await getDocs(sensorQuery);
        sensorDocs.forEach((doc) => {
          sensorIds.push(doc.data().DeviceID);
        });

        const filters = [where("DeviceId", "in", sensorIds)];
        // readings are batched; get batches where the batch end is after the start date
        // or where the batch start is before the end date
        // e.g. we want all of these
        // <--------------s--------------------------e----------> range
        // <----s-----------e-----------------------------------> batch A
        // <-----------------s------------------e---------------> batch B
        // <-----------------------------------s----------e-----> batch C
        if (start) {
          filters.push(where("TimeEnd", ">=", dateToFirestoreTimestamp(start)));
        }
        // if (end)
        //   filters.push(where("TimeStart", "<=", dateToFirestoreTimestamp(end)));

        const q = query(collection(db, "sensorreadings"), ...filters);
        const querySnapshot = await getDocs(q);

        for (let sensorId of sensorIds) {
          data[sensorId] = querySnapshot.docs
            .filter((batch) => batch.data().DeviceId === sensorId)
            .flatMap((batch) =>
              batch
                .data()
                .readings.filter((r) => {
                  if (startDate && r.Time.toDate() < start) return false;
                  if (endDate && r.Time.toDate() > end) return false;
                  return true;
                })
                .map((r) => ({ ...r, DocID: batch.id }))
            );
          data[sensorId].sort((a, b) => a.Time - b.Time);
        }
        return data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sensorreadings"]);
    },
    enabled: sensors.length > 0,
  });

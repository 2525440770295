import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../config/firebase";
import { useAlerts } from "../alerts";

import { Loading } from "../loadingContext";
import { SetPassword } from "./set-password";

export const Onboarding = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { addAlert } = useAlerts();
  const navigate = useNavigate();

  let otp = searchParams.get("otp");
  let uid = searchParams.get("uid");
  const setFirstTimePassword = httpsCallable(
    functions,
    "set_first_time_password"
  );

  const onSubmit = async (e) => {
    let data = {
      otp: otp,
      uid: uid,
      password: e.password,
    };

    try {
      setLoading(true);
      await setFirstTimePassword({ data });
      setLoading(false);
      navigate(`/login`);
      addAlert({
        message: "Your new password was set correctly, please proceed to login",
        type: "success",
      });
    } catch (err) {
      addAlert({
        message: err.message,
        type: "error",
      });
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <SetPassword onSubmit={onSubmit} />
    </>
  );
};

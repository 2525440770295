import { handleFormErrors } from "../util";
import { useReducer, useState } from "react";

export const SetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    if (password.length < 6) {
      dispatchErrorUpdate({
        type: "set",
        field: "password",
        message: "Password is too short",
      });
      isValid = false;
    }
    if (password !== confirmPassword) {
      dispatchErrorUpdate({
        type: "set",
        field: "confirmPassword",
        message: "Passwords must be the same",
      });
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    props.onSubmit({ password: password });
  };

  return (
    <div className="h-screen">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 card bg-neutral w-96 shadow-xl">
        <figure className="p-4">
          <img alt="logo" src="/media/logo.jpg"></img>
        </figure>
        <form onSubmit={handleSubmit} className="card-body">
          <div className="text-sm">
            Password must be at least 6 characters in length
          </div>
          <input
            className="input input-bordered w-full max-w-xs"
            placeholder="Password"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "password" });
            }}
          />
          {errors?.password && (
            <span className="select-none text-error">{errors.password}</span>
          )}
          <input
            className="input input-bordered w-full max-w-xs"
            placeholder="Confirm Password"
            type="password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              dispatchErrorUpdate({
                type: "clear",
                field: "confirmPassword",
              });
            }}
          />
          {errors?.confirmPassword && (
            <span className="select-none text-error">
              {errors.confirmPassword}
            </span>
          )}
          <div className="card-actions">
            <button className="btn btn-primary" type="submit">
              Set Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

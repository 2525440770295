import {
  collection,
  doc,
  query,
  getDocs,
  updateDoc,
  addDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "./client";
import { db } from "../../config/firebase";
import { getAuth } from "firebase/auth";

export const useCompanies = ({ activeOnly }) =>
  useQuery({
    queryKey: ["companies", { activeOnly }],
    queryFn: async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      const token = await user.getIdTokenResult();
      if (
        !["admin", "superUser"].includes(token.claims.type) &&
        token.claims.companyIDs.concat(token.claims.visibleCompanyIDs)
          .length === 0
      ) {
        return [];
      }
      try {
        let queryParams = [collection(db, "companies")];
        if (token.claims.type === "user") {
          queryParams.push(
            where(
              "__name__",
              "in",
              token.claims.companyIDs.concat(token.claims.visibleCompanyIDs)
            )
          );
        }
        if (activeOnly) {
          queryParams.push(where("Active", "==", true));
        }
        const q = query(...queryParams);
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });

export const useCreateCompany = () =>
  useMutation({
    mutationKey: ["createCompany"],
    mutationFn: async (companyData) => {
      try {
        await addDoc(collection(db, "companies"), companyData);
      } catch (error) {
        console.log(error);
      }
      return doc;
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["companies"]);
    },
  });

export const useEditCompany = () =>
  useMutation({
    mutationKey: ["editCompany"],
    mutationFn: async (companyData) => {
      try {
        await updateDoc(doc(db, "companies", companyData.id), companyData);
      } catch (error) {
        console.log(error);
      }
      return doc;
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["companies"]);
    },
  });

export const useDeleteCompany = (target_id) =>
  useMutation({
    mutationKey: ["deleteCompany"],
    mutationFn: async (target_id) => {
      try {
        await deleteDoc(doc(db, "companies", target_id));
      } catch (error) {
        console.log(error);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["companies"]);
    },
  });

import { useEffect, useState } from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./App.css";
// import { db } from "./config/firebase";

import { queryClient } from "./api/queries/client";
import { Users } from "./components/pages/users/users";
import { Companies } from "./components/pages/companies/companies";
import { Sites } from "./components/pages/sites/sites";
import { Sensors } from "./components/pages/sensors/sensors";
import { Permissions } from "./components/pages/permissions/permissions";
import { Login } from "./components/pages/login";
import { Charts } from "./components/pages/charts/charts";
import { Status } from "./components/pages/status";
import { Admin } from "./components/pages/admin";
import { PrivateRoute } from "./components/routing/PrivateRoute";
import { DialogProvider } from "./components/dialogContext";
import { LoadingProvider } from "./components/loadingContext";
import { AlertProvider } from "./components/alerts";
import { Onboarding } from "./components/pages/onboarding";
import { ForgottenPassword } from "./components/pages/forgotten-password";
import { ResetPassword } from "./components/pages/reset-password";

function App() {
  const [authentication, setAuthState] = useState({
    authenticated: false,
    initialized: true,
  });

  useEffect(
    () =>
      getAuth().onAuthStateChanged((user) => {
        if (user) {
          setAuthState({
            authenticated: true,
            initialized: false,
          });
        } else {
          setAuthState({
            authenticated: false,
            initialized: false,
          });
        }
      }),
    []
  );

  if (authentication.initialized) {
    return;
  }

  return (
    <div className="App">
      <main>
        <QueryClientProvider client={queryClient}>
          <LoadingProvider>
            <AlertProvider>
              <DialogProvider>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  buttonPosition="bottom-left"
                />
                <BrowserRouter>
                  <Routes>
                    <Route path={"/login"} element={<Login />} exact />
                    <Route path={"/forgotten-password"} element={<ForgottenPassword />} exact />
                    <Route path={"/reset-password"} element={<ResetPassword />} exact />
                    <Route path={"/onboarding"} element={<Onboarding />} exact />
                    <Route path={"/forgotten-password"} element={<ForgottenPassword />} exact />
                    <Route path={"/reset-password"} element={<ResetPassword />} exact />
                    <Route
                      path={"/"}
                      element={
                        <PrivateRoute
                          authenticated={authentication.authenticated}
                        >
                          <Charts />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={"/status"}
                      element={
                        <PrivateRoute
                          authenticated={authentication.authenticated}
                        >
                          <Status />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path={"/admin"}
                      element={
                        <PrivateRoute
                          authenticated={authentication.authenticated}
                          requiredUserType={"admin"}
                        >
                          <Admin />
                        </PrivateRoute>
                      }
                    >
                      <Route path={"users"} element={<Users />} />
                      <Route path={"companies"} element={<Companies />} />
                      <Route path={"sites"} element={<Sites />} />
                      <Route path={"sensors"} element={<Sensors />} />
                      <Route path={"permissions"} element={<Permissions />} />
                    </Route>
                    <Route component={Error} />
                  </Routes>
                </BrowserRouter>
              </DialogProvider>
            </AlertProvider>
          </LoadingProvider>
        </QueryClientProvider>
      </main>
    </div>
  );
}

export default App;

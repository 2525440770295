import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useDialog } from "../../dialogContext";
import { SensorDialog } from "./dialog";
import {
  useSensors,
  useCreateSensor,
  useEditSensor,
  useDeleteSensor,
} from "../../../api/queries/sensors";
import { Loading } from "../../loadingContext";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { useUserToken } from "../../util";
import {
  useCreateSensorStatus,
  useDeleteSiteDownloadStatus,
  useEditSensorStatus,
} from "../../../api/queries/sitedownloadstatus";

export const Sensors = () => {
  useUserToken();

  const { unSetDialog, setDialog } = useDialog();
  const sensors = useSensors({ activeOnly: false });
  const deleteSensor = useDeleteSensor();
  const createSensor = useCreateSensor();
  const createSiteDownloadStatus = useCreateSensorStatus();
  const editSiteDownloadStatus = useEditSensorStatus();
  const deleteSiteDownloadStatus = useDeleteSiteDownloadStatus();
  const editSensor = useEditSensor();

  const onNewSensor = async (formData) => {
    let siteData = formData.SiteData;
    delete formData.SiteData;
    let siteDownloadStatus = { ...siteData.data(), ...formData };

    let newSensorID = await createSensor.mutateAsync(formData);
    siteDownloadStatus.SensorID = newSensorID;

    createSiteDownloadStatus.mutate(siteDownloadStatus);

    unSetDialog();
  };

  const onEditSensor = async (formData) => {
    let siteData = formData.SiteData;
    delete formData.SiteData;

    let siteDownloadStatus = { ...formData, ...siteData.data() };
    editSensor.mutate(formData);

    editSiteDownloadStatus.mutate(siteDownloadStatus);
    unSetDialog();
  };

  const onDeleteSensor = (targetID) => {
    function deleteSensorStatus(targetID) {
      deleteSensor.mutate(targetID);
      deleteSiteDownloadStatus.mutate(targetID);
    }
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record?",
      buttons: [
        { label: "Yes", onClick: () => deleteSensorStatus(targetID) },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnClickOutside: true,
      closeOnEscape: true,
    });
  };

  return (
    <div className="overflow-x-auto w-full h-full">
      {(createSensor.isPending ||
        editSensor.isPending ||
        deleteSensor.isPending) && <Loading />}
      <button
        className="m-2 fixed bottom-8 right-0 z-50"
        onClick={() =>
          setDialog(
            <SensorDialog onSubmit={onNewSensor} unSetDialog={unSetDialog} />
          )
        }
      >
        <PlusCircleIcon className="h-10 w-10 z-50" />
      </button>

      <table className="table table-zebra">
        <thead>
          <tr>
            <th>Site</th>
            <th>Device ID</th>
            <th>Start date</th>
            <th>Active</th>
            <th className="w-24"></th>
            <th className="w-24"></th>
          </tr>
        </thead>
        <tbody>
          {sensors.isLoading ? (
            <Loading />
          ) : sensors.isError ? (
            <></>
          ) : (
            sensors.data.map((sensorDoc) => (
              <tr key={sensorDoc.id}>
                <td>{sensorDoc.data().Name}</td>
                <td>{sensorDoc.data().DeviceID}</td>
                {sensorDoc.data().StartDate ? (
                  <td>
                    {sensorDoc
                      .data()
                      .StartDate.toDate()
                      .toISOString()
                      .substring(0, 10)}
                  </td>
                ) : (
                  <td></td>
                )}
                {sensorDoc.data().Active ? <td>Yes</td> : <td>No</td>}
                <td>
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() =>
                      setDialog(
                        <SensorDialog
                          onSubmit={onEditSensor}
                          unSetDialog={unSetDialog}
                          sensor={sensorDoc.data()}
                          id={sensorDoc.id}
                        />
                      )
                    }
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() => onDeleteSensor(sensorDoc.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

import { useState, useEffect } from "react";

import { useOutlet, Link } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/solid";

import { Footer } from "../footer";
import { NavBar } from "../navbar";
import { useUserToken } from "../util";

export const Admin = () => {
  const token = useUserToken();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const outlet = useOutlet();

  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return (
    <div>
      <NavBar />
      <div className="min-h-screen">
        <div className="drawer lg:drawer-open">
          <input
            id="drawer"
            type="checkbox"
            className="drawer-toggle"
            checked={drawerOpen}
            onChange={(e) => setDrawerOpen(e.target.checked)}
          />
          <div className="drawer-content">
            <div className="grid">
              <label
                htmlFor="drawer"
                className="btn btn-ghost btn-sm drawer-button lg:hidden m-2 justify-self-start"
              >
                <Bars3Icon className="h-8 w-8" />
              </label>
              {outlet || (
                <>
                  {isMobile && (
                    <h1 className="pt-3 px-4">
                      Use the burger menu to add or edit sensors, companies,
                      etc.
                    </h1>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="drawer-side">
            <label
              htmlFor="drawer"
              aria-label="close sidebar"
              className="drawer-overlay"
            ></label>
            <ul className="menu p-4 w-80 min-h-full bg-base-200 text-base-content">
              <li onClick={() => setDrawerOpen(false)}>
                <Link to="companies">Companies</Link>
              </li>
              <li onClick={() => setDrawerOpen(false)}>
                <Link to="sites">Sites</Link>
              </li>
              <li onClick={() => setDrawerOpen(false)}>
                <Link to="sensors">Sensors</Link>
              </li>
              {token?.claims.type !== "user" &&
                token?.claims.type !== "superUser" && (
                  <>
                    <li onClick={() => setDrawerOpen(false)}>
                      <Link to="users">Users</Link>
                    </li>
                    <li onClick={() => setDrawerOpen(false)}>
                      <Link to="permissions">Permissions</Link>
                    </li>
                  </>
                )}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

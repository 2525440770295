import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { useDialog } from "../../dialogContext";
import { PermissionDialog } from "./dialog";
import {
  usePermissions,
  useCreatePermission,
  useEditPermission,
  useDeletePermission,
} from "../../../api/queries/permissions";
import { Loading } from "../../loadingContext";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

export const Permissions = () => {
  const { unSetDialog, setDialog } = useDialog();
  const permissions = usePermissions();
  const deletePermission = useDeletePermission();
  const createPermission = useCreatePermission();
  const editPermission = useEditPermission();

  const onNewPermission = (formData) => {
    createPermission.mutate(formData);
    unSetDialog();
  };

  const onEditPermission = (formData) => {
    editPermission.mutate(formData);
    unSetDialog();
  };

  const onDeletePermission = (target_id) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record?",
      buttons: [
        { label: "Yes", onClick: () => deletePermission.mutate(target_id) },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnClickOutside: true,
      closeOnEscape: true,
    });
  };

  return (
    <div className="overflow-x-auto w-full h-full">
      {(createPermission.isPending ||
        editPermission.isPending ||
        deletePermission.isPending) && <Loading />}
      <button
        className="m-2 fixed bottom-8 right-0 z-50"
        onClick={() =>
          setDialog(
            <PermissionDialog
              onSubmit={onNewPermission}
              unSetDialog={unSetDialog}
            />
          )
        }
      >
        <PlusCircleIcon className="h-10 w-10 z-50" />
      </button>

      <table className="table table-zebra">
        <thead>
          <tr>
            <th>User</th>
            <th>Company</th>
            <th>Site</th>
            <th className="w-24"></th>
            <th className="w-24"></th>
          </tr>
        </thead>
        <tbody>
          {permissions.isLoading ? (
            <Loading />
          ) : permissions.isError ? (
            <tr>
              <td colSpan="4">An error occured while loading data</td>
            </tr>
          ) : (
            permissions.data.map((permissionDoc) => (
              <tr key={permissionDoc.id}>
                <td>{permissionDoc.data().UserName}</td>
                <td>{permissionDoc.data().CompanyName}</td>
                <td>{permissionDoc.data().SiteName}</td>
                <td>
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() =>
                      setDialog(
                        <PermissionDialog
                          onSubmit={onEditPermission}
                          unSetDialog={unSetDialog}
                          permission={permissionDoc.data()}
                          id={permissionDoc.id}
                        />
                      )
                    }
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() => onDeletePermission(permissionDoc.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

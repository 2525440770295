import React, { useEffect, useReducer, useState, useContext } from "react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";

const ALERT_FADE_TIME_S = 3.5;

export const Alert = (props) => {
  let [closed, setClosed] = useState(false);

  useEffect(() => {
    if (!props.persistent) {
      let interval = window.setInterval(() => {
        if (new Date().getTime() - props.added > 1000 * ALERT_FADE_TIME_S) {
          setClosed(true);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, []);

  if (closed) return null;

  return (
    <div
      className={`${
        props.className || ""
      } alert mt-3 sm:mt-0 sm:mb-3 shadow-lg ${
        {
          error: "alert-error",
          warning: "alert-warning",
          success: "alert-success",
          info: "alert-info",
        }[props.type]
      } ${props.persistent ? "" : "animate-fade"}`}
      onClick={() => {
        setClosed(true);
      }}
    >
      <div className="flex flex-row items-center">
        {
          {
            error: <ExclamationCircleIcon className="h-8 w-8 mr-3" />,
            warning: <ExclamationCircleIcon className="h-8 w-8 mr-3" />,
            success: <CheckCircleIcon className="h-8 w-8 mr-3" />,
            info: <InformationCircleIcon className="h-8 w-8 mr-3" />,
          }[props.type]
        }
        <span>{props.children}</span>
      </div>
    </div>
  );
};

export const AlertContext = React.createContext({
  alerts: [],
  addAlert: (alert) => {},
  cleanAlerts: () => {},
});

const alertReducer = (state, action) => {
  let now = new Date().getTime();
  switch (action.type) {
    case "filter": {
      return {
        ...state,
        alerts: state.alerts.filter((item) => {
          return item.alert.persistent || now - item.added < 1000 * 5;
        }), // remove alert after 5 seconds } as AlertState;
      };
    }
    case "add": {
      if (!action.alert) {
        throw new Error("Must provide an alert to the add action");
      }
      let alerts = [...state.alerts];
      alerts.push({
        alert: action.alert,
        added: now,
        key: now + action.alert.message,
      });
      return { ...state, alerts };
    }
    default: {
      throw new Error("Must provide a valid action (filter | add)");
    }
  }
};

const AlertDisplay = (props) => {
  const { cleanAlerts } = useContext(AlertContext);

  useEffect(() => {
    const interval = setInterval(() => {
      let now = new Date().getTime();
      cleanAlerts();
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <aside className="fixed sm:top-0 sm:mt-24 sm:bottom-auto bottom-0 sm:pt-4 w-full md:w-1/2 sm:left-1/2 sm:right-auto sm:-translate-x-1/2 left-0 right-0 z-40">
      {props.messages &&
        props.messages.map((item) => (
          <Alert
            key={item.key}
            type={item.alert.type}
            persistent={item.alert.persistent}
            added={item.added}
          >
            {item.alert.message}
          </Alert>
        ))}
    </aside>
  );
};

export const AlertProvider = (props) => {
  const initialState = {
    alerts: [],
    addAlert: (alert) => {
      dispatch({ type: "add", alert });
    },
    cleanAlerts: () => {
      dispatch({ type: "filter" });
    },
  };
  const [state, dispatch] = useReducer(alertReducer, initialState);

  return (
    <AlertContext.Provider value={state}>
      {props.children}
      <AlertDisplay messages={state.alerts} />
    </AlertContext.Provider>
  );
};

export const AlertDebugButton = (props) => {
  const context = useContext(AlertContext);
  return (
    <button
      className="absolute bottom-2 right-2 btn btn-outline btn-xs z-50"
      type="button"
      onClick={() => {
        context.addAlert({
          message: "Test Alert",
          persistent: false,
          type: "info",
        });
      }}
    >
      Alert
    </button>
  );
};

export const useAlerts = () => useContext(AlertContext);

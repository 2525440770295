import React, { useEffect, useReducer, useState } from "react";
import Select from "react-select";

import { useCompanies } from "../../../api/queries/companies";
import { useDownloadFrequency } from "../../../api/queries/downloadFrequencies";
import { useSiteTypes } from "../../../api/queries/siteTypes";
import { useSensorDataTypes } from "../../../api/queries/sensorDataTypes";
import {
  handleFormErrors,
  toOptionArray,
  customSelectStyles,
} from "../../util";
import { Loading } from "../../loadingContext";
import { useSecurityKey } from "../../../api/queries/sites";

export const SiteDialog = (props) => {
  const securityKey = useSecurityKey(props.id);
  const companies = useCompanies({ activeOnly: false });
  const downloadFrequencies = useDownloadFrequency();
  const sensorDataTypes = useSensorDataTypes();
  const siteTypes = useSiteTypes();
  const [SerialNumber, setSerialNumber] = useState(
    props.site?.SerialNumber || ""
  );
  const [SecurityKey, setSecurityKey] = useState(securityKey.data || "");
  const [Company, setCompany] = useState({
    label: props.site?.CompanyName,
    value: props.site?.CompanyID,
  });
  const [Name, setName] = useState(props.site?.Name || "");
  const [SiteType, setSiteType] = useState({
    label: props.site?.SiteTypeDescription,
    value: props.site?.SiteTypeID,
  });
  const [SensorDataType, setSensorDataType] = useState({
    label: props.site?.SensorDataTypeDescription,
    value: props.site?.SensorDataTypeID,
  });
  const [IPAddress, setIpAddress] = useState(props.site?.IPAddress || "");
  const [Port, setPort] = useState(props.site?.Port || "");
  const [DownloadFrequency, setDownloadFrequency] = useState({
    label: props.site?.DownloadFrequencyDescription,
    value: props.site?.DownloadFrequencyID,
  });
  const [Active, setActive] = useState(
    props.site?.Active === undefined ? true : props.site.Active
  );
  const [displayTrusenseForm, setDisplayTrusenseForm] = useState(true);

  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});

  useEffect(() => {
    if (securityKey.data) {
      setSecurityKey(securityKey.data);
    }
  }, [securityKey.data]);

  useEffect(() => {
    if (SiteType.label == "Trusense") {
      setIpAddress("");
      setPort("");
      setDisplayTrusenseForm(true);
    } else {
      setSecurityKey("");
      setDisplayTrusenseForm(false);
    }
  }, [SiteType]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    if (!Company.value) {
      dispatchErrorUpdate({
        type: "set",
        field: "company",
        message: "Company is a required field",
      });
      isValid = false;
    }
    if (!Name) {
      dispatchErrorUpdate({
        type: "set",
        field: "name",
        message: "Name is a required field",
      });
      isValid = false;
    }
    if (SiteType.label === "Trusense" && !SerialNumber) {
      dispatchErrorUpdate({
        type: "set",
        field: "serialNumber",
        message: "Serial Number is a required for Trusense sites",
      });
      isValid = false;
    }
    if (SiteType.label === "Trusense" && !SecurityKey) {
      dispatchErrorUpdate({
        type: "set",
        field: "securityKey",
        message: "Security is a required field for Trusense sites",
      });
      isValid = false;
    }
    if (!SiteType.value) {
      dispatchErrorUpdate({
        type: "set",
        field: "siteType",
        message: "Site Type is a required field",
      });
      isValid = false;
    }
    if (!SensorDataType.value) {
      dispatchErrorUpdate({
        type: "set",
        field: "sensorDataType",
        message: "Data Type is a required field",
      });
      isValid = false;
    }
    if (!DownloadFrequency.value) {
      dispatchErrorUpdate({
        type: "set",
        field: "downloadFrequency",
        message: "Download Frequency is a required field",
      });
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    let CompanyID = Company.value;
    let CompanyName = Company.label;
    let SiteTypeID = SiteType.value;
    let SiteTypeDescription = SiteType.label;
    let SensorDataTypeID = SensorDataType.value;
    let SensorDataTypeDescription = SensorDataType.label;
    let DownloadFrequencyID = DownloadFrequency.value;
    let DownloadFrequencyDescription = DownloadFrequency.label;
    let id = props.id || "";

    const data = {
      CompanyID,
      CompanyName,
      Name,
      SerialNumber,
      SiteTypeID,
      SiteTypeDescription,
      SensorDataTypeID,
      SensorDataTypeDescription,
      IPAddress,
      Port,
      SecurityKey,
      DownloadFrequencyID,
      DownloadFrequencyDescription,
      Active,
      id,
    };
    props.onSubmit(data);
  };

  return (
    <form method="POST" onSubmit={handleSubmit}>
      {(companies.isLoading ||
        downloadFrequencies.isLoading ||
        sensorDataTypes.isLoading ||
        securityKey.isLoading ||
        siteTypes.isLoading) && <Loading />}
      <h3 className="font-bold text-lg p-4">
        {props.site ? "Edit" : "Create"} Site
      </h3>
      <div className="grid grid-rows-5 grid-flow-col gap-4">
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Company</span>
          <Select
            className={"input w-full max-w-xs rounded-md p-0"}
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            value={Company}
            options={toOptionArray(companies.data, "Name")}
            onChange={(value) => {
              setCompany(value);
              dispatchErrorUpdate({ type: "clear", field: "company" });
            }}
          ></Select>
          {errors?.company && (
            <span className="select-none text-error">{errors.company}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Name</span>
          <input
            type="text"
            placeholder="Name"
            className="input input-bordered w-full max-w-xs bg-neutral"
            value={Name}
            onChange={(e) => {
              setName(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "name" });
            }}
          />
          {errors?.name && (
            <span className="select-none text-error">{errors.name}</span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Data type</span>
          <Select
            className="input w-full max-w-xs rounded-md p-0"
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            value={SensorDataType}
            options={toOptionArray(sensorDataTypes.data, "Description")}
            onChange={(value) => {
              dispatchErrorUpdate({ type: "clear", field: "sensorDataType" });
              setSensorDataType(value);
            }}
          ></Select>
          {errors?.sensorDataType && (
            <span className="select-none text-error">
              {errors.sensorDataType}
            </span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Download Frequency</span>
          <Select
            className="input w-full max-w-xs rounded-md p-0"
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            value={DownloadFrequency}
            options={toOptionArray(downloadFrequencies.data, "Description")}
            onChange={(value) => {
              dispatchErrorUpdate({
                type: "clear",
                field: "downloadFrequency",
              });
              setDownloadFrequency(value);
            }}
          ></Select>
          {errors?.downloadFrequency && (
            <span className="select-none text-error">
              {errors.downloadFrequency}
            </span>
          )}
        </label>
        <label className="flex flex-col gap-1 items-center">
          <span className="block text-sm font-medium">Site type</span>
          <Select
            className="input w-full max-w-xs rounded-md p-0"
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            value={SiteType}
            options={toOptionArray(siteTypes.data, "Description")}
            onChange={(value) => {
              setSiteType(value);
              dispatchErrorUpdate({ type: "clear", field: "siteType" });
            }}
          ></Select>
          {errors?.siteType && (
            <span className="select-none text-error">{errors.siteType}</span>
          )}
        </label>
        <>
          {displayTrusenseForm ? (
            <>
              <label className="flex flex-col gap-1 items-center">
                <span className="block text-sm font-medium">Security Key</span>
                <input
                  type="number"
                  placeholder="Security Key"
                  className="input input-bordered w-full max-w-xs bg-neutral"
                  value={SecurityKey}
                  onChange={(e) => {
                    setSecurityKey(e.target.value);
                    dispatchErrorUpdate({
                      type: "clear",
                      field: "securityKey",
                    });
                  }}
                />
                {errors?.securityKey && (
                  <span className="select-none text-error">
                    {errors.securityKey}
                  </span>
                )}
              </label>
              <label className="flex flex-col gap-1 items-center">
                <span className="block text-sm font-medium">Serial Number</span>
                <input
                  type="text"
                  placeholder="Serial Number"
                  className="input input-bordered w-full max-w-xs bg-neutral"
                  value={SerialNumber}
                  onChange={(e) => {
                    setSerialNumber(e.target.value);
                    dispatchErrorUpdate({
                      type: "clear",
                      field: "serialNumber",
                    });
                  }}
                />
                {errors?.serialNumber && (
                  <span className="select-none text-error">
                    {errors.serialNumber}
                  </span>
                )}
              </label>
            </>
          ) : (
            <>
              <label className="flex flex-col gap-1 items-center">
                <span className="block text-sm font-medium">IP Address</span>
                <input
                  type="text"
                  placeholder="IP address"
                  className="input input-bordered w-full max-w-xs bg-neutral"
                  value={IPAddress}
                  onChange={(e) => setIpAddress(e.target.value)}
                />
              </label>
              <label className="flex flex-col gap-1 items-center">
                <span className="block text-sm font-medium">Port</span>
                <input
                  type="text"
                  placeholder="Port"
                  className="input input-bordered w-full max-w-xs bg-neutral"
                  value={Port}
                  onChange={(e) => setPort(e.target.value)}
                />
              </label>
            </>
          )}
        </>
        <label>
          <span className="block text-sm font-medium">Active</span>
          <input
            type="checkbox"
            checked={Active}
            onChange={(e) => setActive(e.target.checked)}
          />
        </label>
      </div>
      <div className="pt-4">
        <button type="submit" className="btn mr-1 mt-2">
          Submit
        </button>
        <button
          type="button"
          className="btn ml-1 mt-2"
          onClick={props.unSetDialog}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

import { QueryClient } from "@tanstack/react-query";

const STALE_TIME = 1000 * 60 * 10; // 10 minutes as milliseconds

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: STALE_TIME,
            refetchOnWindowFocus: false,
        }
    }
});

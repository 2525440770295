import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

import { DEBUG } from "./constants";

const firebaseConfig = {
  apiKey: "AIzaSyDhHUrEcJ9HZrJbqkFTxb2o-yQQZdiS5As",
  authDomain: "hydrometrics-app.firebaseapp.com",
  projectId: "hydrometrics-app",
  storageBucket: "hydrometrics-app.appspot.com",
  messagingSenderId: "883415307581",
  appId: "1:883415307581:web:a7997e41913fff63dda154",
  measurementId: "G-X5DQY6EG4C",
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);

if (DEBUG) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
}

import { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleFormErrors } from "../util";
import { useSearchParams } from "react-router-dom";
import { useAlerts } from "../alerts";

import { Tooltip } from "react-tooltip";

import { InformationCircleIcon } from "@heroicons/react/24/solid";

import { Loading } from "../loadingContext";

export const ResetPassword = () => {
  const { addAlert } = useAlerts();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, dispatchErrorUpdate] = useReducer(handleFormErrors, {});
  const [loading, setLoading] = useState(false);

  let uid = searchParams.get("uid");

  const resetPassword = async (e) => {
    e.preventDefault();

    let isValid = true;
    if (password.length < 6) {
      dispatchErrorUpdate({
        type: "set",
        field: "password",
        message: "Password must be at least 6 characters long",
      });
      isValid = false;
    }
    if (!password) {
      dispatchErrorUpdate({
        type: "set",
        field: "password",
        message: "Password is a required field",
      });
      isValid = false;
    }
    if (!confirmPassword) {
      dispatchErrorUpdate({
        type: "set",
        field: "confirmPassword",
        message: "Confirm Password is a required field",
      });
      isValid = false;
    }
    if (password !== confirmPassword) {
      dispatchErrorUpdate({
        type: "set",
        field: "confirmPassword",
        message: "Passwords must be the same",
      });
      isValid = false;
    }
    if (!isValid) {
      return;
    }

    if (loading) {
      return <Loading />;
    }
    let data = {
      uid: uid,
      password: password,
    };

    try {
      setLoading(true);
      //   await setFirstTimePassword({ data });
      setLoading(false);
      navigate(`/login`);
      addAlert({
        message: "Your new password was set correctly, please proceed to login",
        type: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="h-screen">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 card bg-neutral w-96 shadow-xl">
        <figure className="p-4">
          <img alt="logo" src="/media/logo.jpg"></img>
        </figure>
        <form onSubmit={resetPassword} className="card-body">
          <input
            className="input input-bordered w-full max-w-xs"
            placeholder="Password"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "password" });
            }}
          />
          {errors?.password && (
            <span className="select-none text-error">{errors.password}</span>
          )}
          <input
            className="input input-bordered w-full max-w-xs"
            placeholder="Confirm Password"
            type="password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              dispatchErrorUpdate({ type: "clear", field: "confirmPassword" });
            }}
          />
          {errors?.confirmPassword && (
            <span className="select-none text-error">
              {errors.confirmPassword}
            </span>
          )}
          <div className="card-actions">
            <button className="btn btn-primary" type="submit">
              Set Password
            </button>
            <div className="password-tooltip">
              <InformationCircleIcon className="h-6 hover:cursor-pointer" />
            </div>
            <Tooltip
              anchorSelect=".password-tooltip"
              place="top"
              content="Password must be at lease 6 characters in length"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

import {
  collection,
  doc,
  query,
  getDocs,
  updateDoc,
  addDoc,
  deleteDoc,
  where,
  getDoc,
} from "firebase/firestore";
import { useMutation, useQuery } from "@tanstack/react-query";

import { db } from "../../config/firebase";
import { getAuth } from "firebase/auth";
import { queryClient } from "./client";

export const useSensors = ({ activeOnly }) =>
  useQuery({
    queryKey: ["sensors", { activeOnly }],
    queryFn: async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      const token = await user.getIdTokenResult();
      if (
        !["admin", "superUser"].includes(token.claims.type) &&
        token.claims.visibleCompanyIDs.length === 0
      ) {
        return [];
      }
      if (["admin", "superUser"].includes(token.claims.type)) {
        let queryParams = [collection(db, "sensors")];
        if (activeOnly) {
          queryParams.push(where("Active", "==", true));
        }
        try {
          const querySnapshot = await getDocs(query(...queryParams));
          return querySnapshot.docs;
        } catch (error) {
          console.log(error);
          throw error;
        }
      }
      try {
        let specificSitesQuery;
        let allSitesQuery;
        let specificSitesParams = [collection(db, "sensors")];
        let allSitesParams = [collection(db, "sensors")];
        if (activeOnly) {
          specificSitesParams.push(where("Active", "==", true));
          allSitesParams.push(where("Active", "==", true));
        }
        if (token.claims.siteIDs.length !== 0) {
          specificSitesParams.push(where("SiteID", "in", token.claims.siteIDs));
          specificSitesParams.push(
            where("CompanyID", "in", token.claims.visibleCompanyIDs)
          );
          specificSitesQuery = getDocs(query(...specificSitesParams));
        }
        if (token.claims.companyIDs.length !== 0) {
          allSitesParams.push(
            where("CompanyID", "in", token.claims.companyIDs)
          );
          allSitesQuery = getDocs(query(...allSitesParams));
        }
        let querySnapshotResults = await Promise.all(
          [specificSitesQuery, allSitesQuery].filter((q) => q !== undefined)
        );
        let result = new Set(querySnapshotResults.flatMap((qsr) => qsr.docs));
        return Array.from(result);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });

export const useCreateSensor = () =>
  useMutation({
    mutationKey: ["createSensor"],
    mutationFn: async (sensorData) => {
      try {
        const siteDocRef = doc(db, "sites", sensorData.SiteID);
        const siteDocSnap = await getDoc(siteDocRef);
        sensorData.CompanyID = siteDocSnap.data().CompanyID;
        sensorData.CompanyName = siteDocSnap.data().CompanyName;
        sensorData.SensorDataTypeDescription =
          siteDocSnap.data().SensorDataTypeDescription;
        sensorData.SensorDataTypeID = siteDocSnap.data().SensorDataTypeID;
        sensorData.SiteSerialNumber = siteDocSnap.data().SerialNumber;
        const result = await addDoc(collection(db, "sensors"), sensorData);
        return result.id;
      } catch (error) {
        console.log(error);
      }
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sensors"]);
    },
  });

export const useEditSensor = () =>
  useMutation({
    mutationKey: ["editSensor"],
    mutationFn: async (sensorData) => {
      try {
        const siteDocRef = doc(db, "sites", sensorData.SiteID);
        const siteDocSnap = await getDoc(siteDocRef);
        sensorData.CompanyID = siteDocSnap.data().CompanyID;
        sensorData.CompanyName = siteDocSnap.data().CompanyName;
        sensorData.SensorDataTypeDescription =
          siteDocSnap.data().SensorDataTypeDescription;
        sensorData.SensorDataTypeID = siteDocSnap.data().SensorDataTypeID;
        sensorData.SiteSerialNumber = siteDocSnap.data().SerialNumber;

        await updateDoc(doc(db, "sensors", sensorData.id), sensorData);
      } catch (error) {
        console.log(error);
      }
      return doc;
    },
    retry: false,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sensors"]);
    },
  });

export const useDeleteSensor = (target_id) =>
  useMutation({
    mutationKey: ["deleteSensor"],
    mutationFn: async (target_id) => {
      try {
        await deleteDoc(doc(db, "sensors", target_id));
      } catch (error) {
        console.log(error);
      }
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sensors"]);
    },
  });

import { collection, query, getDocs } from "firebase/firestore";

import { db } from "../../config/firebase";
import { useQuery } from "@tanstack/react-query";

export const useSiteTypes = () =>
  useQuery({
    queryKey: ["sitetypes"],
    queryFn: async () => {
      try {
        const q = query(collection(db, "sitetypes"));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  });

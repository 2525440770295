import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { userTypes } from "./types";
import { useDialog } from "../../dialogContext";
import { CreateUserDialog, EditUserDialog } from "./dialog";
import {
  useUsers,
  useCreateUser,
  useEditUser,
  useDeleteUser,
} from "../../../api/queries/users";
import { Loading } from "../../loadingContext";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

export const Users = () => {
  const { unSetDialog, setDialog } = useDialog();
  const users = useUsers();
  const createUser = useCreateUser();
  const deleteUser = useDeleteUser();
  const editUser = useEditUser();

  const onCreateUser = async (formData) => {
    const user = await createUser.mutateAsync(formData);

    if (user.error) {
      // setError(user.error);
      setDialog(
        <CreateUserDialog
          onSubmit={onCreateUser}
          unSetDialog={unSetDialog}
          error={user.error}
        />
      );
      return;
    }

    unSetDialog();
    setDialog(
      <EditUserDialog
        onSubmit={onEditUser}
        unSetDialog={unSetDialog}
        user={user}
        error={user.error}
        ID={user.ID}
      />
    );
  };

  const onEditUser = (formData) => {
    editUser.mutate(formData);
    unSetDialog();
  };

  const onDeleteUser = (targetID) => {
    confirmAlert({
      title: "Delete",
      message: "Are you sure you want to delete this record?",
      buttons: [
        { label: "Yes", onClick: () => deleteUser.mutate(targetID) },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnClickOutside: true,
      closeOnEscape: true,
    });
  };

  return (
    <div className="overflow-x-auto w-full h-full">
      {/* {(createUser.isPending || editUser.isPending || deleteUser.isPending) && (
        <Loading />
      )} */}
      <button
        className="m-2 fixed bottom-8 right-0 z-50"
        onClick={() =>
          setDialog(
            <CreateUserDialog
              onSubmit={onCreateUser}
              unSetDialog={unSetDialog}
            />
          )
        }
      >
        <PlusCircleIcon className="h-10 w-10 z-50" />
      </button>

      <table className="table table-zebra">
        <thead>
          <tr>
            <th>User Name</th>
            <th>Email</th>
            <th>Type</th>
            <th className="w-24"></th>
            <th className="w-24"></th>
          </tr>
        </thead>

        <tbody>
          {users.isLoading ? (
            <Loading />
          ) : users.isError ? (
            <tr>
              <td colSpan="4">An error occured while loading data</td>
            </tr>
          ) : (
            users.data.map((userDoc) => (
              <tr key={userDoc.id}>
                <td>{userDoc.data().Name}</td>
                <td>{userDoc.data().Email}</td>
                <td>{userTypes.find((t) => t.value == userDoc.data().Type)?.label}</td>
                <td className="text-right">
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() =>
                      setDialog(
                        <EditUserDialog
                          onSubmit={onEditUser}
                          unSetDialog={unSetDialog}
                          user={userDoc.data()}
                          ID={userDoc.id}
                        />
                      )
                    }
                  >
                    Edit
                  </button>
                </td>
                <td className="text-right">
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() => onDeleteUser(userDoc.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
